<template>
	<component
		:is="inspectComponent"
		ref="inspection"
		:service="service"
		:checklist-type="service.checklist_type"
		:can-update="false"/>
</template>

<script>
import PreServiceTGD1XInspection from "@components/service/tgd1x/PreServiceTGD1XInspection.vue"
import InvalidChecklistType from "@components/service/InvalidChecklistType.vue"


export default {
	props: {
		service: {
			type: null,
			default: () => [],
		},
		inspectionData : {
			type: null,
			default: () => {},
		},
	},
	computed: {
		inspectComponent() {
			if (this.service.id > 0) {
				switch (this.service.checklist_type) {
					case 'tgd1x' :
						this.setInspectData(this.inspectionData)
						return PreServiceTGD1XInspection
					default :
						return InvalidChecklistType
				}
			}
			return undefined
		} ,
	},
	watch: {
		inspectionData : {
			handler()  {
				this.setInspectData(this.inspectionData)
			}
		}
	},
	methods: {
		setInspectData(inspectionObj) {
			this.$nextTick(() => {
				const inspectComp = this.$refs.inspection
				if (inspectComp && 'setData' in inspectComp) {
					if (inspectionObj) {
						inspectComp.setData(inspectionObj)
					} else {
						inspectComp.setData({})
					}
				}
			})
		}
	},
}
</script>
