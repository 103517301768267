<i18n locale="th" lang="yaml" >
select.firmware.placeholder : "เลือก Firmware"
</i18n>

<template>
	<a-select
		class="myselect-firmware"
		:placeholder="$t('select.firmware.placeholder')"
		:value="value ? value : undefined"
		:allow-clear="allowClear"
		:disabled="disabled"
		:get-popup-container="getPopupContainer"
		@change="handleChange">
		<a-select-option v-for="firmware of firmwares" :key="firmware.id" :value="firmware.id" :disabled="firmware.disabled">
			{{firmware.name}}
		</a-select-option>
	</a-select>
</template>

<script>
import {Select} from "ant-design-vue"
import {mapGetters} from "vuex"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-select" : Select,
		"a-select-option" : Select.Option,
	} ,
	mixins : [PopupMixin] ,
	model : {
		prop : 'value',
		event : 'change',
	} ,
	props : {
		value : {
			type : null,
			default : undefined
		} ,
		firmwareSetId : {
			type : Number ,
			default : -1,
		} ,
		allowClear : {
			type : Boolean,
			default : false,
		} ,
		disabled : {
			type : Boolean,
			default : false,
		} ,
		disabledFirmwares : {
			type : null ,
			default : undefined
		}
	} ,
	computed : {
		...mapGetters('drone',['getFirmwaresBySet','getFirmwareById']) ,
		firmwares() {
			if (!this.disabledFirmwares) {
				return this.getFirmwaresBySet(this.firmwareSetId)
			} else {
				const checked = Array.isArray(this.disabledFirmwares) ? this.disabledFirmwares : [this.disabledFirmwares]
				return this.getFirmwaresBySet(this.firmwareSetId).map((f) => {
					if (checked.includes(f.id)) {
						return {...f,disabled: true}
					} else {
						return f
					}
				})
			}
		}
	} ,
	methods : {
		handleChange(value) {
			this.$emit('change',value);
			this.$emit('changeFirmware',this.getFirmwareById(value))
		}
	}
}
</script>

<style lang="less" scoped>
.myselect-firmware {
	width : 200px;
}
</style>
