<i18n locale="th" lang="yaml" >
tgd1x.pre_service_print.checklist.drone_operation: "การทดสอบการทำงานของโดรน"
tgd1x.pre_service_print.checklist.others: "สาเหตุอื่น ๆ"
tgd1x.pre_service_print.checklist.camera_model: "กล้องรุ่น"
tgd1x.pre_service_print.checklist.camera_status : "การใช้งานกล้อง"
tgd1x.pre_service_print.checklist.camera_switching : "สลับกล้อง"
tgd1x.pre_service_print.checklist.camera_top : "กล้องหน้า"
tgd1x.pre_service_print.checklist.camera_bottom : "กล้องล่าง"
tgd1x.pre_service_print.checklist.transmitter : "Transmitter"
tgd1x.pre_service_print.checklist.light_front : "ไฟหน้า"
tgd1x.pre_service_print.checklist.light_bottom : "ไฟล่าง"
tgd1x.pre_service_print.checklist.laser : "เลเซอร์"
tgd1x.pre_service_print.checklist.pump : "ปั๊ม"
tgd1x.pre_service_print.checklist.usb_a_connector : "ช่องต่อสาย USB A ไม่โยก"
tgd1x.pre_service_print.checklist.microusb_connector : "ช่องชาร์ตแบต Micro USB ไม่โยก"
tgd1x.pre_service_print.checklist.remote_antenna : "เสาอากาศรีโมท ไม่เสียหาย"
tgd1x.pre_service_print.checklist.tablet_holder : "ที่จับจอไม่หลวม น็อตครบ"
</i18n>

<template>
	<div class="pre_service-checklist">
		<div class="pre_service-checklist-column">
			<div class="pre_service-checklist-block">
				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span>2</span> {{ $t('tgd1x.pre_service_print.checklist.drone_operation') }} </label>
				</div>
				<ServiceChecklistRowPrint
					v-for="prop in droneTestCheckboxProps"
					:key="prop"
					v-model="checklistData.power[prop]"
					:label="$t('tgd1x.pre_service_print.checklist.' + prop)"/>
			</div>
			<div class="pre_service-checklist-block">
				<div class="checklist-row checklist-row-title checklist-row-border">
					<label class="title"> <span>3</span> {{ $t('tgd1x.pre_service_print.checklist.others') }} </label>
				</div>
				<ServiceChecklistRowPrint
					v-for="prop in droneExternalCheckboxProps"
					:key="prop"
					v-model="checklistData.external[prop]"
					:others="true"
					:label="$t('tgd1x.pre_service_print.checklist.' + prop)"/>
			</div>
		</div>
	</div>

</template>

<script>
import ServiceChecklistRowPrint from '../ServiceChecklistRowPrint.vue'
import { replaceDeepProperty } from '@utils/objectUtil'
function _defaultChecklistData() {
	return {
		power : {
			camera_model : '' ,
			camera_status : undefined ,
			camera_switching : undefined,
			camera_top : undefined,
			camera_bottom : undefined,
			transmitter : undefined,
			light_front : undefined,
			light_bottom : undefined,
			laser : undefined,
			pump : undefined,
		} ,

		external : {
			usb_a_connector : undefined,
			microusb_connector : undefined,
			remote_antenna : undefined,
			tablet_holder : undefined,
		}

	}
}
export default {
	components: {
		ServiceChecklistRowPrint,
	},
	props: {
		service: {
			type: null,
			default: () => [],
		}
	},
	data() {
		return {
			checklistData: _defaultChecklistData(),
		}
	},
	computed: {
		droneTestCheckboxProps() {
			return Object.keys(this.checklistData.power)
		},
		droneExternalCheckboxProps() {
			return Object.keys(this.checklistData.external)
		},
	},
	methods: {
		setData(data) {
			this.checklistData = _defaultChecklistData()
			if (data) {
				replaceDeepProperty(this.checklistData, data)
			}
		}
	},
}
</script>

<style lang="less" scoped>
.pre_service-checklist-column {
	display: flex;
	flex-direction: row;
}
.pre_service-checklist-block {
	flex-grow: 1;
	flex-basis: 400px;
	min-width: 325px;
	max-width: 400px;
	margin-bottom: 24px;
	padding-right: 16px;

	.mobile & {
		max-width: 100%;
		padding-right: 0;
		flex-basis: 275px;
		min-width: 275px;
	}
}
@media print {
	.checklist-row {
		> label {
			-webkit-print-color-adjust: exact;
		}
	}
}
</style>
